import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
  RowWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"
import SeoComp from "../../components/SeoComp"

const RgpdPage = ({ intl, data }) =>
  data &&
  data.allDirectusRgpdPageTranslation.nodes.length > 0 && (
    <Fragment>
      <SeoComp
        description={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].description
        }
        title={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].title
        }
        twitter_card={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].twitter_card
        }
        image={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].image &&
          data.allDirectusSeoTranslation.nodes[0].image.data &&
          data.allDirectusSeoTranslation.nodes[0].image.data.url
        }
        hreflangPathname={{
          fr: "rgpd",
          en: "rgpd",
          de: "rgpd",
          es: "rgpd",
        }}
      />
      <SectionContainer>
        <SectionInnerWrapper>
          {process.env &&
          data.allDirectusRgpdPageTranslation.nodes[0].content &&
          process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
            <TextBox
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusRgpdPageTranslation.nodes[0].gbp_content,
              }}
            />
          ) : (
            <TextBox
              dangerouslySetInnerHTML={{
                __html: data.allDirectusRgpdPageTranslation.nodes[0].content,
              }}
            />
          )}
        </SectionInnerWrapper>
      </SectionContainer>

      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h1 {
    margin-bottom: 1.3em;
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    font-weight: normal;
  }

  h2,
  h3 {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    font-weight: normal;
  }

  > p {
    margin-bottom: 1.3em;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusRgpdPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        content
        gbp_content
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "rgpd" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(RgpdPage)
